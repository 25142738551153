import React from 'react'
import './LegalNotice.css';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Impressum = () => {
    const { t } = useTranslation();
    return (
        <div className="UILegalNotice">

            <div className="container-fluid background">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-7">
                            <div className="heading">
                                <h1>{t('pages.impressum')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container content">
                <div className="text">
                    <p><strong>{t('impressum.company-info-title')}</strong><br/>
                    {t('impressum.text1')}<br/>
                    {t('impressum.text2')}<br/>
                    {t('impressum.text3')}<br/>
                    {t('impressum.text4')}</p>
                    <p><strong>{t('impressum.represented-by')}</strong><br/>
                    {t('impressum.managing-director')}</p>
                    <p><strong>{t('impressum.contact')}</strong><br/>
                    {t('impressum.text5')}: <a href='tel:+491736802402'>+491736802402</a><br/>
                    {t('impressum.text6')}: <a href='mailto:office@ngen.de'>office@ngen.de</a><br/>
                    Web: <a href='/'>www.ngen-group.eu</a></p>
                    <p><strong>{t('impressum.register-entry')}</strong><br/>
                    {t('impressum.register-entry-data1')}<br/>
                    {t('impressum.register-entry-data2')}<br/>
                    {t('impressum.register-entry-data3')}</p>                  
                    <p><strong>{t('impressum.text9')}</strong><br/>
                    {t('impressum.vat-id-data')}</p>
                    <p><strong>{t('impressum.disclaimer')}</strong><br/>
                    {t('impressum.disclaimer-text')}</p>
                    <p><strong>{t('impressum.text19')}</strong><br/>
                    {t('impressum.text18')}
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Impressum